import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../../components/footer"
import "../common.css"
import Fg1 from '../../images/b1.jpg'
import Fg2 from '../../images/b2.jpg'
import Head from "../../components/header"


const BusinessPage = () => (
  <Layout>
    <Head pageTitle="事業内容 - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>事業内容</h4>
          <h5>GAZIRUとは</h5>
          <p>画像認識サービス「GAZIRU」は、スマートフォンなどのモバイル端末のカメラを通して、紙面、衣類や自動車などの様々な画像を形状などの特徴から高精度に分類し、その対象物の付加情報や広告配信など、画像認識と情報提供を組み合わせた新たなサービスの構築を可能とする基盤サービスです。</p>

          <br />

          <h5>GAZIRUの特徴</h5>
          <h6>世界最高レベルの画像認識技術 × 認識対象を選ばない汎用サービス</h6>
          <p>画像から絵柄毎に異なった値を有する特徴量（数値列）を抽出し、データベースと照合することで、「高精度」「高速性」「汎用性」を実現します。</p>

          <h6>1 認識対象を選ばない</h6>
          <p>画像認証は顔、指紋など認証対象を限定するのが一般的ですが、GAZIRUは認証対象を選ばず、様々な定型品（工業製品）や不定形品（自然物）に適用が可能なため、幅広い領域での画像とスマートフォンを活用した新しいサービス・ソリューションの実現が可能となります。</p>

          <h6>2 専用機器が不要</h6>
          <p>スマートフォン付属のカメラでも撮影が可能です。認識インターフェースは各種クライアントから利用できます。<br />
          ※対象物や要求精度によっては、専用のカメラでの撮影が必要です。</p>

          <h6>3 クラウドでの提供</h6>
          <p>月額利用型でサービスを提供します。<br />
          新たにサーバ等の購入は不要で、迅速に画像認識サービスの開始が可能です。<br />
          ※個体識別は、ソフトウェアパッケージも提供しています。</p>

          <br />

          <h5>GAZIRU製品紹介動画</h5>
          <br />
          <div className="moviep">
            <div className="movie"><iframe loading="lazy" title="GAZIRU + Dinolite" width="757" height="426" src="https://www.youtube.com/embed/maNtJ5ACDlk?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          </div><br />

          <br />

          <h5>適用・応用分野</h5>
          <img src={Fg1}></img><br />

          <br />

          <h6>販売促進（平面物認識）</h6>
          <p>カタログやチラシから動画やWebと連動するメディアミックス広告やECサイトへの誘導、クーポンの提供が可能です。</p>
          <h6>ブランド商品の真贋判定（個体識別）</h6>
          <p>個体識別サービスを使うと、ブランド品のパーツの一部のデータを事前登録しておき、後に個品の同一箇所の撮影を行うことで個体識別し、真贋判定を行うことができます。</p>
          <h6>C to C 取引での正規品（同一品）判定（個体識別）</h6>
          <p>消費者同士が取引するECサイト等で、売り手が商品を事前登録しておき、買い手が受け取り後照合することで、同一品を受け取ったかを確認することができます。安心して取引を行うサービスを実現することができます。</p>
          <h6>製品管理（トレーサビリティ）（個体識別）</h6>
          <p>大きさ・形状・コストなどにより個体識別タグ等を付与しにくい、自動車等の機械部品、生産品、商品パッケージなどを生産時に登録し、工場内での出荷までのトレーサビリティ、出荷後のトレーサビリティを実現し、品質管理等を行うことができます。</p>
          <p>応用事例についてさらに詳しくは<Link className="text-decoration-none mx-2" to="/ja/examples">導入事例</Link> をご確認ください。</p>

          <br />

          <h5>サービス利用の流れ</h5>
          <img src={Fg2}></img><br />

          <br />

          <p>実現したいシステム・サービス内容にもよりますが、個体識別等の画像認識部分は、大まかに以下のような流れで実施されます。</p>
          <h6>1. データベースの作成</h6>
             <p>サービス事業者様は、あらかじめ「GAZIRU」のデータベースに対象物の画像を登録し辞書を作成。また、対象物が特定されたあとのアクションや付加情報（付加情報表示、指定広告URLへの誘導など）を定義しサービスを構築します。</p>

          <h6>2.ユーザが対象物を撮影</h6>
             <p>エンドユーザ様は、スマートフォン上で「GAZIRU」用アプリケーションを起動し、認識したい対象物の画像を「GAZIRU」に送信します。</p>
             <p>工場でのトレーサビリティ等の場合は、ラインに設置した撮像装置により、より安定的な撮像条件で登録を行うことで、多くの個体を高速に識別することができるシステムを構築できます。</p>

          <h6>3.対象物を認識</h6>
             <p>「GAZIRU」が受信した画像と、あらかじめ登録されたデータベース上の画像との特徴情報を元に検索・分類し、対象物を認識します。登録時と撮影条件を近づけるほど、正確な個体識別が可能になります。</p>

          <h6>4.さまざまなサービスを配信</h6>
             <p>認識結果に基づき、お客様が求めるアクションを実行するようにアプリを設定したり、システムを構築したりします。たとえば、商品情報等の付加情報をスマートフォンに表示する、指定広告URLへ誘導するリンクを提示する、製品ロット情報を端末画面に表示する、などです。</p>

          <br />

          <h5>お見積り・お問い合わせ</h5>
             <p>GAZIRUのご利用料金はサービスの種類、ご利用になる機能によって異なります。</p>
             <p>お客様にとって最適な機能とご利用料金をご案内いたしますので、お気軽に以下のフォームからお問い合わせください。</p>

            <p><Link className="text-decoration-none" to="/notification/contact/ja">お問い合わせフォーム</Link></p>

            <br />
            <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default BusinessPage
